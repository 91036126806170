/**
 * Export
 * API für Export
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: support@landdata.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

import { ApproveExportConfigRequestDTO } from '../model/models';
import { ApproveExportConfigResponseDTO } from '../model/models';
import { CreateExportConfigErrorDTO } from '../model/models';
import { CreateExportConfigRequestDTO } from '../model/models';
import { CreateExportConfigResponseDTO } from '../model/models';
import { EstimationDTO } from '../model/models';
import { EstimationFilterDTO } from '../model/models';
import { ExportConfigDTO } from '../model/models';
import { ExportConfigStatusDTO } from '../model/models';
import { ExportJobPageDTO } from '../model/models';
import { FirmaDTO } from '../model/models';
import { PageableDTO } from '../model/models';
import { UpdateExportConfigRequestDTO } from '../model/models';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';



@Injectable({
  providedIn: 'root'
})
export class DatevIncometaxExportConfigService {

    protected basePath = 'http://localhost:8080';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }


    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key,
                        (value as Date).toISOString().substr(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * Anfragen eines Logins
     * null
     * @param approveExportConfigRequestDTO 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public approveExportConfigRequest(approveExportConfigRequestDTO: ApproveExportConfigRequestDTO, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<ApproveExportConfigResponseDTO>;
    public approveExportConfigRequest(approveExportConfigRequestDTO: ApproveExportConfigRequestDTO, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<ApproveExportConfigResponseDTO>>;
    public approveExportConfigRequest(approveExportConfigRequestDTO: ApproveExportConfigRequestDTO, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<ApproveExportConfigResponseDTO>>;
    public approveExportConfigRequest(approveExportConfigRequestDTO: ApproveExportConfigRequestDTO, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (approveExportConfigRequestDTO === null || approveExportConfigRequestDTO === undefined) {
            throw new Error('Required parameter approveExportConfigRequestDTO was null or undefined when calling approveExportConfigRequest.');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<ApproveExportConfigResponseDTO>(`${this.configuration.basePath}/api/v1/export/datev/incometax/export-config/login`,
            approveExportConfigRequestDTO,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Anfragen einer Export Config
     * null
     * @param createExportConfigRequestDTO 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createExportConfigRequest(createExportConfigRequestDTO: CreateExportConfigRequestDTO, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<CreateExportConfigResponseDTO>;
    public createExportConfigRequest(createExportConfigRequestDTO: CreateExportConfigRequestDTO, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<CreateExportConfigResponseDTO>>;
    public createExportConfigRequest(createExportConfigRequestDTO: CreateExportConfigRequestDTO, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<CreateExportConfigResponseDTO>>;
    public createExportConfigRequest(createExportConfigRequestDTO: CreateExportConfigRequestDTO, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (createExportConfigRequestDTO === null || createExportConfigRequestDTO === undefined) {
            throw new Error('Required parameter createExportConfigRequestDTO was null or undefined when calling createExportConfigRequest.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (oauth-ld) required
        credential = this.configuration.lookupCredential('oauth-ld');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<CreateExportConfigResponseDTO>(`${this.configuration.basePath}/api/v1/export/datev/incometax/export-config`,
            createExportConfigRequestDTO,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Löschen einer Export Konfiguration
     * null
     * @param exportConfigId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteExportConfig(exportConfigId: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<any>;
    public deleteExportConfig(exportConfigId: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpResponse<any>>;
    public deleteExportConfig(exportConfigId: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpEvent<any>>;
    public deleteExportConfig(exportConfigId: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined}): Observable<any> {
        if (exportConfigId === null || exportConfigId === undefined) {
            throw new Error('Required parameter exportConfigId was null or undefined when calling deleteExportConfig.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (oauth-ld) required
        credential = this.configuration.lookupCredential('oauth-ld');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.delete<any>(`${this.configuration.basePath}/api/v1/export/datev/incometax/export-config/${encodeURIComponent(String(exportConfigId))}`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Prognose der entstehenden Export Jobs
     * null
     * @param estimationFilter 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public estimateExportJobs(estimationFilter?: EstimationFilterDTO, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<EstimationDTO>;
    public estimateExportJobs(estimationFilter?: EstimationFilterDTO, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<EstimationDTO>>;
    public estimateExportJobs(estimationFilter?: EstimationFilterDTO, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<EstimationDTO>>;
    public estimateExportJobs(estimationFilter?: EstimationFilterDTO, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (estimationFilter !== undefined && estimationFilter !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>estimationFilter, 'estimationFilter');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (oauth-ld) required
        credential = this.configuration.lookupCredential('oauth-ld');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<EstimationDTO>(`${this.configuration.basePath}/api/v1/export/datev/incometax/estimate`,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Anfragen einer Export Konfiguration
     * null
     * @param exportConfigId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getExportConfig(exportConfigId: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<ExportConfigDTO>;
    public getExportConfig(exportConfigId: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<ExportConfigDTO>>;
    public getExportConfig(exportConfigId: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<ExportConfigDTO>>;
    public getExportConfig(exportConfigId: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (exportConfigId === null || exportConfigId === undefined) {
            throw new Error('Required parameter exportConfigId was null or undefined when calling getExportConfig.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (oauth-ld) required
        credential = this.configuration.lookupCredential('oauth-ld');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<ExportConfigDTO>(`${this.configuration.basePath}/api/v1/export/datev/incometax/export-config/${encodeURIComponent(String(exportConfigId))}`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Abfragen von Fehlern zu einer Export Konfiguration
     * null
     * @param exportConfigId 
     * @param pageable 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getExportConfigJobs(exportConfigId: string, pageable?: PageableDTO, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<ExportJobPageDTO>;
    public getExportConfigJobs(exportConfigId: string, pageable?: PageableDTO, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<ExportJobPageDTO>>;
    public getExportConfigJobs(exportConfigId: string, pageable?: PageableDTO, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<ExportJobPageDTO>>;
    public getExportConfigJobs(exportConfigId: string, pageable?: PageableDTO, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (exportConfigId === null || exportConfigId === undefined) {
            throw new Error('Required parameter exportConfigId was null or undefined when calling getExportConfigJobs.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (pageable !== undefined && pageable !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>pageable, 'pageable');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (oauth-ld) required
        credential = this.configuration.lookupCredential('oauth-ld');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<ExportJobPageDTO>(`${this.configuration.basePath}/api/v1/export/datev/incometax/export-config/${encodeURIComponent(String(exportConfigId))}/jobs`,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Anfragen des Verbindungsstatus einer Export Konfiguration
     * null
     * @param exportConfigId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getExportConfigStatus(exportConfigId: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<ExportConfigStatusDTO>;
    public getExportConfigStatus(exportConfigId: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<ExportConfigStatusDTO>>;
    public getExportConfigStatus(exportConfigId: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<ExportConfigStatusDTO>>;
    public getExportConfigStatus(exportConfigId: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (exportConfigId === null || exportConfigId === undefined) {
            throw new Error('Required parameter exportConfigId was null or undefined when calling getExportConfigStatus.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (oauth-ld) required
        credential = this.configuration.lookupCredential('oauth-ld');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<ExportConfigStatusDTO>(`${this.configuration.basePath}/api/v1/export/datev/incometax/export-config/${encodeURIComponent(String(exportConfigId))}/status`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Abfragen einer Firma anhand dessen ID
     * null
     * @param firmaId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getFirma(firmaId: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<FirmaDTO>;
    public getFirma(firmaId: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<FirmaDTO>>;
    public getFirma(firmaId: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<FirmaDTO>>;
    public getFirma(firmaId: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (firmaId === null || firmaId === undefined) {
            throw new Error('Required parameter firmaId was null or undefined when calling getFirma.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (oauth-ld) required
        credential = this.configuration.lookupCredential('oauth-ld');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<FirmaDTO>(`${this.configuration.basePath}/api/v1/export/datev/incometax/firma/${encodeURIComponent(String(firmaId))}`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Abfragen von Firmen anhand einer Betriebsnummer
     * null
     * @param betriebsnummer 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getFirmaByBetriebsnummer(betriebsnummer: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<FirmaDTO>>;
    public getFirmaByBetriebsnummer(betriebsnummer: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<FirmaDTO>>>;
    public getFirmaByBetriebsnummer(betriebsnummer: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<FirmaDTO>>>;
    public getFirmaByBetriebsnummer(betriebsnummer: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (betriebsnummer === null || betriebsnummer === undefined) {
            throw new Error('Required parameter betriebsnummer was null or undefined when calling getFirmaByBetriebsnummer.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (oauth-ld) required
        credential = this.configuration.lookupCredential('oauth-ld');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<Array<FirmaDTO>>(`${this.configuration.basePath}/api/v1/export/datev/incometax/firma/betriebsnummer/${encodeURIComponent(String(betriebsnummer))}`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Abfragen von Firmen anhand einer Firmennummer
     * null
     * @param firmennummer 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getFirmaByFirmennummer(firmennummer: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<FirmaDTO>>;
    public getFirmaByFirmennummer(firmennummer: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<FirmaDTO>>>;
    public getFirmaByFirmennummer(firmennummer: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<FirmaDTO>>>;
    public getFirmaByFirmennummer(firmennummer: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (firmennummer === null || firmennummer === undefined) {
            throw new Error('Required parameter firmennummer was null or undefined when calling getFirmaByFirmennummer.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (oauth-ld) required
        credential = this.configuration.lookupCredential('oauth-ld');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<Array<FirmaDTO>>(`${this.configuration.basePath}/api/v1/export/datev/incometax/firma/firmennummer/${encodeURIComponent(String(firmennummer))}`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Abfragen von Firmen anhand einer Partnernummer
     * null
     * @param partnernummer 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getFirmaByPartnernummer(partnernummer: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<FirmaDTO>>;
    public getFirmaByPartnernummer(partnernummer: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<FirmaDTO>>>;
    public getFirmaByPartnernummer(partnernummer: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<FirmaDTO>>>;
    public getFirmaByPartnernummer(partnernummer: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (partnernummer === null || partnernummer === undefined) {
            throw new Error('Required parameter partnernummer was null or undefined when calling getFirmaByPartnernummer.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (oauth-ld) required
        credential = this.configuration.lookupCredential('oauth-ld');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<Array<FirmaDTO>>(`${this.configuration.basePath}/api/v1/export/datev/incometax/firma/partnernummer/${encodeURIComponent(String(partnernummer))}`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Trennt die Verbindung zur Gegenseite der Export Konfiguration
     * null
     * @param exportConfigId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postExportConfigDisconnect(exportConfigId: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<any>;
    public postExportConfigDisconnect(exportConfigId: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpResponse<any>>;
    public postExportConfigDisconnect(exportConfigId: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpEvent<any>>;
    public postExportConfigDisconnect(exportConfigId: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined}): Observable<any> {
        if (exportConfigId === null || exportConfigId === undefined) {
            throw new Error('Required parameter exportConfigId was null or undefined when calling postExportConfigDisconnect.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (oauth-ld) required
        credential = this.configuration.lookupCredential('oauth-ld');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<any>(`${this.configuration.basePath}/api/v1/export/datev/incometax/export-config/${encodeURIComponent(String(exportConfigId))}/disconnect`,
            null,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Aktualisiert die DATEV Verbindung
     * null
     * @param exportConfigId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public reconnectExportConfig(exportConfigId: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<CreateExportConfigResponseDTO>;
    public reconnectExportConfig(exportConfigId: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<CreateExportConfigResponseDTO>>;
    public reconnectExportConfig(exportConfigId: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<CreateExportConfigResponseDTO>>;
    public reconnectExportConfig(exportConfigId: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (exportConfigId === null || exportConfigId === undefined) {
            throw new Error('Required parameter exportConfigId was null or undefined when calling reconnectExportConfig.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (oauth-ld) required
        credential = this.configuration.lookupCredential('oauth-ld');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<CreateExportConfigResponseDTO>(`${this.configuration.basePath}/api/v1/export/datev/incometax/export-config/${encodeURIComponent(String(exportConfigId))}/reconnect`,
            null,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Aktualisierung einer Export Konfiguration
     * null
     * @param exportConfigId 
     * @param updateExportConfigRequestDTO 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateExportConfig(exportConfigId: string, updateExportConfigRequestDTO: UpdateExportConfigRequestDTO, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<any>;
    public updateExportConfig(exportConfigId: string, updateExportConfigRequestDTO: UpdateExportConfigRequestDTO, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpResponse<any>>;
    public updateExportConfig(exportConfigId: string, updateExportConfigRequestDTO: UpdateExportConfigRequestDTO, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpEvent<any>>;
    public updateExportConfig(exportConfigId: string, updateExportConfigRequestDTO: UpdateExportConfigRequestDTO, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined}): Observable<any> {
        if (exportConfigId === null || exportConfigId === undefined) {
            throw new Error('Required parameter exportConfigId was null or undefined when calling updateExportConfig.');
        }
        if (updateExportConfigRequestDTO === null || updateExportConfigRequestDTO === undefined) {
            throw new Error('Required parameter updateExportConfigRequestDTO was null or undefined when calling updateExportConfig.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (oauth-ld) required
        credential = this.configuration.lookupCredential('oauth-ld');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<any>(`${this.configuration.basePath}/api/v1/export/datev/incometax/export-config/${encodeURIComponent(String(exportConfigId))}`,
            updateExportConfigRequestDTO,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
